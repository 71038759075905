<template>
  <nav v-show="!isAnythingOnTopVisible" class="navbar" style="z-index: 9999" :class="{ sticky: isSticky }">
    <div id="navbar" class="navbar-container md:tw-py-2" style="transition: top 0.3s">
      <a href="/" class="logo-link w-inline-block w--current tw-flex-shrink-0"
        ><img :src="company.logo" loading="lazy" :alt="`${company.name} Logo`" class="tw-w-[130px] md:tw-w-[120px]"
      /></a>
      <div class="horizontal-flex d-flex flex-row align-items-center header-button">
        <div class="tw-hidden md:tw-flex">
          <nc-button
            :label="headerButton.text"
            :is-link="true"
            :external="true"
            :link="headerButton.url"
            :height="45"
          />
        </div>
        <div data-w-id="49a3e5ed-3971-a0f7-bd72-3c9323acdb5d" class="menu-hamburger mt-2">
          <div class="menu-line top"></div>
          <div class="menu-line bottom"></div>
        </div>
      </div>
    </div>
    <div id="menu-scrolling-content" class="menu" style="background-color: var(--dark-brown); overflow-y: auto">
      <div class="nav-menu-content-wrapper tw-pt-32 lg:tw-pt-[10rem]">
        <div class="nav-menu-content">
          <div v-for="(menuGroup, groupIndex) in allMenu" :key="`groupIndex-${groupIndex}`" class="item-menu-wrapper">
            <div v-for="(menu, menuIndex) in menuGroup" :key="`menuGroup-${menuIndex}`" class="nav-overflow-hidden">
              <nuxt-link
                v-if="menu.url !== 'url'"
                :to="menu.url"
                class="nav-link w-inline-block w--current"
                :class="`_${menuIndex + 1 + (groupIndex === 1 ? 5 : 0)}`"
                :external="true"
                :target="menu.is_open_new_tab ? '_blank' : ''"
                data-w-id="49a3e5ed-3971-a0f7-bd72-3c9323acdb7b"
              >
                <div class="nav-wrapper">
                  <div
                    class="nav-text tw-text-[2rem] md:tw-text-[3rem] lg:tw-text-[4rem]"
                    :class="routeUrl === menu.url ? 'nav-active' : ''"
                  >
                    {{ menu.name }}
                  </div>
                </div>
              </nuxt-link>
              <a
                v-else
                :href="menu.url"
                aria-current="page"
                class="nav-link w-inline-block w--current"
                :class="`_${menuIndex + 1 + (groupIndex === 1 ? 5 : 0)}`"
                data-w-id="49a3e5ed-3971-a0f7-bd72-3c9323acdb7b"
              >
                <div class="nav-wrapper">
                  <div
                    class="nav-text tw-text-[2rem] md:tw-text-[3rem] lg:tw-text-[4rem]"
                    :class="routeUrl === menu.url ? 'nav-active' : ''"
                  >
                    {{ menu.name }}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div v-if="true" class="nav-menu-bottom">
          <div class="w-layout-grid social-media-component nav-menu">
            <div
              v-if="vars.facebookUrl"
              id="w-node-_49a3e5ed-3971-a0f7-bd72-3c9323acdb9d-23acdb27"
              class="social-link-wrapper"
            >
              <a
                id="w-node-_49a3e5ed-3971-a0f7-bd72-3c9323acdb9e-23acdb27"
                :href="vars.facebookUrl"
                target="_blank"
                class="social-link nav-bottom w-inline-block"
              >
                <div></div>
              </a>
            </div>
            <div
              v-if="vars.instagramUrl"
              id="w-node-_09a0c56e-c35b-e8af-f5b2-ede5cb258f41-23acdb27"
              class="social-link-wrapper"
            >
              <a :href="vars.instagramUrl" target="_blank" class="social-link nav-bottom w-inline-block">
                <div></div>
              </a>
            </div>
            <div
              v-if="vars.youtubeUrl"
              id="w-node-_49a3e5ed-3971-a0f7-bd72-3c9323acdb95-23acdb27"
              class="social-link-wrapper"
            >
              <a :href="vars.youtubeUrl" target="_blank" class="social-link nav-bottom w-inline-block">
                <div></div>
              </a>
            </div>
            <div
              v-if="vars.linkedinUrl"
              id="w-node-_49a3e5ed-3971-a0f7-bd72-3c9323acdb99-23acdb27"
              class="social-link-wrapper"
            >
              <a
                id="w-node-_49a3e5ed-3971-a0f7-bd72-3c9323acdb9a-23acdb27"
                :href="vars.linkedinUrl"
                target="_blank"
                class="social-link nav-bottom w-inline-block"
              >
                <div></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { MenuTypes, ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import NcButton from '~/components/common/inputs/NcButton.vue';
import { mapState } from 'pinia';
import { useCoreStore } from '~/units/core/store';

type Menu = {};

export default defineNuxtComponent({
  name: 'Theme2BaseHeaderOne',
  components: { NcButton },

  mixins: [ComponentMixin],

  props: {
    headerMenu: {
      required: false,
      type: Array as PropType<Menu[]>,
    },

    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      isSticky: false,
    };
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    this.hideHeaderWhenScrollingInsideMenu();
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isSticky = window.scrollY > 800;
    },

    hideHeaderWhenScrollingInsideMenu() {
      const getElement = () => {
        return document.getElementById('menu-scrolling-content');
      };

      const element = getElement();

      if (element) {
        let prevScrollTop = element.scrollTop;
        element.onscroll = function () {
          const ctxElement = getElement();
          const navbar = document.getElementById('navbar');

          const currentScrollTop = ctxElement ? ctxElement.scrollTop : 0;
          // 70 is estimated scroll height to start showing header inside menu
          if (currentScrollTop < 70 && prevScrollTop > currentScrollTop && navbar) {
            navbar.style.top = '0';
          } else if (navbar) {
            navbar.style.top = '-300px';
          }
          prevScrollTop = currentScrollTop;
        };
      }
    },
  },

  computed: {
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    headerButton() {
      return {
        text: this.vars.buttonText || 'Book a Valuation',
        url: this.vars.buttonUrl || '/valuations/book-a-face-to-face-valuation',
      };
    },

    allMenu() {
      const period = 5;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    columnOneMenu() {
      const content = this.component.variables['column_one_content-text'];

      if (content === MenuTypes.HEADER_MENU) {
        return this.headerMenu;
      }
      return [];
    },
  },
});
</script>

<style scoped>
.navbar {
  position: fixed;
  width: 100vw;
  top: 0;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(46px);
  padding-top: 1.2rem;
  padding-bottom: 1.6rem;
  transition: width 1s cubic-bezier(0.33, 1, 0.4, 1.1);
}

.navbar.sticky {
  background-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 767px) {
  .logo {
    width: 120px;
  }
  .header-button ::v-deep(.button) {
    height: 50px !important;
  }
}
@media only screen and (max-width: 420px) {
  ::v-deep(.button) {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  ::v-deep(.horizontal-flex) {
    grid-column-gap: 0;
  }
}
</style>
